<template>
  <v-container
    fluid
    fill-height
    class="grey lighten-1"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        md="4"
      >
        <v-form>
          <v-img src="" />
          <v-card>
            <v-card-title>{{ $t('signupTitle') }}</v-card-title>
            <v-card-subtitle>{{ $t('signupSubTitle') }}</v-card-subtitle>
            <v-card-text class="mb-0 pb-0">
              <v-select
                prepend-inner-icon="mdi-flag"
                :prefix="$t('language') + ': '"
                outlined
                dense
                class="white mb-4"
                hide-details
                v-model="locale"
                :items="languages"
                item-text="name"
                item-value="key"
              />
              <v-text-field
                ref="business_name"
                :disabled="creatingAccount"
                type="text"
                outlined
                :label="$t('signupBusinessName')"
                v-model="businessName"
                :rules="[validateBusinessName]"
              />
              <v-autocomplete
                ref="business_country"
                :disabled="creatingAccount"
                outlined
                :label="$t('country')"
                v-model="businessCountry"
                :rules="[validateBusinessName]"
                :items="countries"
                item-text="name"
                item-value="code"
              />
              <v-text-field
                ref="displayName"
                :disabled="creatingAccount"
                type="text"
                outlined
                autofocus
                :label="$t('yourName')"
                v-model="displayName"
                :rules="[validateDisplayName]"
              />
              <v-text-field
                ref="email"
                :disabled="creatingAccount"
                type="email"
                outlined
                autofocus
                :label="$t('email')"
                v-model="email"
                :rules="[validateEmail]"
              />
              <v-text-field
                ref="password"
                :disabled="creatingAccount"
                type="password"
                outlined
                :label="$t('password')"
                v-model="password"
                :rules="[validatePasswordUppercaseCharacter, validatePasswordLowercaseCharacter, validatePasswordNumber, validatePasswordSpecialCharacter, validatePasswordLength]"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn
                :disabled="creatingAccount"
                :loading="creatingAccount"
                @click="createAccount"
                block
                color="primary"
                large
              >
                {{ $t('signupAction') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


export default {
    name: 'Signup',
    data() {
        return {
            email: '',
            password: '',
            displayName: '',
            businessName: '',
            businessCountry: 'IS',
            creatingAccount: false,
            displayNameExists: false,
            emailExists: false
        }
    },
    watch: {
        displayName(name) {
            this.$store.dispatch('userExistsByDisplayName', name).then(exists => {
                this.displayNameExists = exists
            })
        },
        email(email) {
            this.$store.dispatch('userExistsByEmail', email).then(exists => {
                this.emailExists = exists
            })
        },
        defaultCountry(newCountry, oldCountry) {
            if(oldCountry === null && newCountry !== null) {
                console.log({
                    locale: this.$i18n.locale,
                    newCountry
                })
                this.$i18n.locale = newCountry.code.toLowerCase()
            }
        }
    },
    computed: {
        languages() {
          return [{name: 'English', key: 'en'}, {name: 'Íslenska', key: 'is'}, {name: 'Svenska', key: 'sv'}, {name: 'Português', key: 'pt'}]
        },
        countries() {
            return this.$store.state.countries
        },
        defaultCountry() {
            return this.$store.state.defaultCountry
        },
        locale: {
            set(value) {
                this.$i18n.locale = value
            },
            get() {
                return this.languages.find(language => language.key === this.$i18n.locale)
            }
        },
        canCreateAccount() {

            if(Object.keys(this.$refs).length === 0) {
                return false
            }

            const emailValid = this.$refs.email.validate()
            const passwordValid = this.$refs.password.validate()
            const businessNameValid = this.$refs.password.validate()
            const displayNameValid = this.$refs.displayName.validate()

            return emailValid && passwordValid && businessNameValid && displayNameValid
        }
    },
    methods: {
        createAccount() {

            if(!this.canCreateAccount) {
                return
            }

            this.creatingAccount = true

            this.$store.dispatch('createAccount', {
                userEmail: this.email,
                userPassword: this.password,
                userDisplayName: this.displayName,
                organizationName: this.businessName,
                organizationCountry: this.businessCountry
            }).then(() => {
                window.top.location.href = 'https://admin.salescloud.is' 
            })
            .catch(() => {
                // Do nothing
            })
            .finally(() => {
                this.creatingAccount = false
            })

        },
        validateEmail(value) {

            if(value === '') {
                return true
            }

            if(this.emailExists) {
                return this.$t('userEmailExists')
            }

            // eslint-disable-next-line no-useless-escape
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(String(value).toLowerCase())
        },
        validatePasswordSpecialCharacter(value) {
            // eslint-disable-next-line no-useless-escape
            const re = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            return !re.test(String(value)) ? this.$t('passwordMustHaveSpecial') : true
        },
        validatePasswordUppercaseCharacter(value) {
            const re = /[A-Z]/
            return !re.test(String(value)) ? this.$t('passwordMustIncludeUppercase') : true
        },
        validatePasswordNumber(value) {
            const re = /[0-9]/
            return !re.test(String(value)) ? this.$t('passwordMustIncludeNumber') : true
        },
        validatePasswordLowercaseCharacter(value) {
            const re = /[a-z]/
            return !re.test(String(value)) ? this.$t('passwordMustIncludeLowercase') : true
        },
        validatePasswordLength(value) {
          return String(value).length <= 10 ? this.$t('passwordMustBeAtLeast10Char') : true
        },
        validateBusinessName(value) {
            return value.length === 0 ? this.$t('pleaseEnterValidBusinessName') : true
        },
        validateDisplayName(value) {

            if(this.displayNameExists) {
                return this.$t('usernameAlreadyExists')
            }

            return value.length === 0 ? this.$t('pleaseEnterFullName') : true
        }
    },
    mounted() {
        this.$store.dispatch('getCountries')
    }
}
</script>